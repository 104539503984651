<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
              >
                <b-row>
                  <!-- Field: Name -->
                  <b-col :md="content.id == 0 ? 12 : 6">
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="content.name"
                          maxlength="300"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Referance ID -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="content.externalReference"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CATEGORY')"
                      label-for="id-category"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CATEGORY')"
                        rules="required"
                      >
                        <v-select
                          id="id-category"
                          v-model="content.tag"
                          :placeholder="$t('SELECT_CATEGORY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allCategories"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Content Type -->
                  <b-col
                    v-if="content.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CONTENT_TYPE')"
                      label-for="id-contentType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CONTENT_TYPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-contentType"
                          v-model="content.contentTypeId"
                          :placeholder="$t('CONTENT_TYPE')"
                          :clearable="false"
                          label="name"
                          class="flex-grow-1"
                          :options="allContentTypes"
                          :reduce="type => type.id"
                          @input="handleChangeContentType()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Content -->
                  <b-col
                    v-if="content.id===0"
                    md="4"
                  >
                    <b-alert
                      show
                      variant="success"
                      class="mt-2"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50 mt-25"
                        />
                        {{ sourceContentName }}
                      </div>
                    </b-alert>

                  </b-col>
                  <b-col
                    v-if="content.id===0"
                    md="2"
                  >
                    <div>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mt-2"
                        :disabled="!(content.contentTypeId > 0)"
                        variant="outline-primary"
                        @click="openTaskModel()"
                      >
                        {{ $t('SELECT_CONTENT') }}
                      </b-button>
                    </div>
                  </b-col>
                  <!-- Field: Frequency -->
                  <b-col
                    v-if="content.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('FREQUENCY')"
                      label-for="id-frequency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('FREQUENCY')"
                        rules="required"
                      >
                        <v-select
                          id="id-frequency"
                          v-model="frequency"
                          :placeholder="$t('SELECT_FREQUENCY')"
                          :clearable="false"
                          label="name"
                          class="flex-grow-1"
                          :options="allFrequencies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Company -->
                  <b-col
                    v-if="(content.id===0) && (companyId===0)"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="content.companyId"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allCompanies"
                          :reduce="type => type.id"
                          @input="handleChangeCompany($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Project -->
                  <b-col
                    v-if="(content.id===0) && (companyId===0)"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('Project')"
                      label-for="id-project"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Project')"
                        rules="required"
                      >
                        <v-select
                          id="id-project"
                          v-model="content.projectId"
                          :placeholder="$t('SELECT_PROJECT')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :reduce="type => type.id"
                          :options="allProjects"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Strat date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="content.start"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: End date -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <flat-pickr
                        id="id-end-date"
                        v-model="content.end"
                        class="form-control"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Field: Length -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('DURATION')"
                      label-for="id-length"
                    >
                      <b-form-input
                        id="id-length"
                        v-model="content.length"
                        type="number"
                        maxlength="3"
                        :placeholder="$t('DURATION')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Analyze Individual -->
                  <b-col
                    md="6"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-is_analyze_individual"
                        v-model="content.analyzeIndividual"
                        name="checkbox-is_analyze_individual"
                        class="mt-2"
                      >
                        {{ $t('AI_INDIVIDUAL_FEEDBACK') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Field: Template -->
                  <b-col
                    md="6"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-template"
                        v-model="content.isTemplate"
                        name="checkbox-template"
                        class="mt-2"
                      >
                        {{ $t('CONTENT_MARK_AS_TEMPLATE') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Field: Enforce Sequence -->
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-enforceSequence"
                        v-model="content.isSequencel"
                        name="checkbox-enforceSequence"
                      >
                        {{ $t('ENABLE_STRICT_SEQUENCE') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Description -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="content.description"
                        maxlength="3000"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Submit and Reset -->
                  <b-col md="12">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ content.id ? $t("BUTTON.UPDATE") : $t("BUTTON.ADD") }}
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                      @click="navigateToList"
                    >
                      {{ $t("BUTTON.CANCEL") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
      <task-model
        :content="content"
        @update-source-content="updateSourceContentName"
      />
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  VBToggle,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-cycle
import { formatDate, avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
import {
  mapUserRoleToVariant,
  updateBreadcrumb,
} from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import TaskModel from './TaskModel.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BAlert,
    TaskModel,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      content: {
        id: 0,
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: '',
        projectId: '',
        companyId: '',
        length: 0,
        name: '',
        start: '',
        end: null,
        tag: '',
        visibility: false,
        isSequencel: false,
        isTemplate: false,
        analyzeIndividual: false,
        description: constants.CONTENT_DESCRIPTION,
      },
      defaultDescription: constants.CONTENT_DESCRIPTION,
      allCategories: constants.CONTENT_TAG,
      breadCrumb: constants.BREADCRUMB,
      projectId: 0,
      companyId: 0,
      category: '',
      frequency: '',
      sourceContentName: this.$t('COPIED_FROM_TEMPLATES'),
      allCompanies: [],
      allProjects: [],
      allContents: [],
      allFrequencies: [],
      allContentTypes: [],
      required,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your content description',
    }
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
      editorOption,
      formatDate,
      mapUserRoleToVariant,
      updateBreadcrumb,
      avatarText,
    }
  },
  async created() {
    try {
      // eslint-disable-next-line radix
      const contentId = router.currentRoute.params.id ? parseInt(router.currentRoute.params.id) : 0
      const { companyId, projectId } = router.currentRoute?.query || {}
      this.companyId = companyId
      this.projectId = projectId
      if (this.companyId) {
        this.content.companyId = this.companyId
      }
      if (this.projectId) {
        this.content.projectId = this.projectId
      }
      // Fetch content types, frequencies, and status in parallel
      await Promise.all([
        this.fetchContentTypes(),
        this.fetchContentFrequencies(),
      ])

      // eslint-disable-next-line no-restricted-globals
      if (contentId && !isNaN(contentId)) {
        // Fetch the content by ID
        this.content.id = contentId
        const response = await this.fetchContentByID(this.content.id)

        if (response) {
          this.content = response.data
          this.configureBreadcrumbs()
          // Fetch related data (projects, companies) in parallel
          await Promise.all([
            this.fetchProjects(this.content.companyId),
            this.fetchCompanies(),
          ])

          // Set values after fetching
          this.setFrequencies()
        }
      } else {
        this.configureBreadcrumbs()
        // If no content ID, fetch companies and contents
        await Promise.all([
          this.fetchCompanies(),
          this.fetchContents(),
        ])
      }
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('project', ['getAllProjects']),
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('project', ['fetchProjectByCompanyID']),
    ...mapActions('content', [
      'createContent',
      'updateContent',
      'fetchContentByID',
      'fetchAllContents',
      'fetchAllContentTypes',
      'fetchAllContentFrequencies',
      'fetchAllContentStatuses',
    ]),
    updateSourceContentName(newSourceContentName) {
      this.sourceContentName = newSourceContentName
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const project = useJwt.getBreadcrumb(this.breadCrumb.PROJECT)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        { text: project.name, to: `/project/project-view/${project.id}`, active: false },
        { text: this.content.name || 'Content Configuration', active: true },
      ]
      updateBreadcrumb(this, breadcrumbs)
    },
    checkContentFrequency(length, frequency, contectTypeId, startDate, endDate) {
      const contentStartDate = new Date(startDate)
      const contentEndDate = endDate ? new Date(endDate) : null

      if (contectTypeId === 2) {
        return true
      }

      if (frequency === constants.CONTENT_FREQUENCY[0].name && !contentEndDate) {
        this.warningMessage(`The end date cannot be empty for the selected frequency: ${frequency}. Please provide a valid end date to match the content frequency requirements`)
        return false
      }

      if (frequency !== constants.CONTENT_FREQUENCY[0].name) {
        const frequencyObj = constants.CONTENT_FREQUENCY.find(f => f.name === frequency)
        if (!frequencyObj) {
          this.warningMessage(`Frequency '${frequency}' is not valid.`)
          return false
        }

        if (contentEndDate) {
          const diffTime = Math.abs(contentEndDate - contentStartDate)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
          if (diffDays < frequencyObj.days) {
            this.warningMessage(`For ${frequency} frequency, start date and end date must be more than ${frequencyObj.days} days apart`)
            return false
          }
        }

        if (length > frequencyObj.days || length === 0) {
          if (length === 0) {
            this.warningMessage('The duration of the content cannot be zero. Please provide a valid duration')
          } else {
            this.warningMessage(`For a '${frequency}' frequency, the duration should be ${frequencyObj.days} days, but currently, the duration is set to ${length} days`)
          }
          return false
        }
        return true
      }
      return true
    },
    openTaskModel() {
      this.$bvModal.show('task-modal')
    },
    handleChangeCompany(id) {
      this.allProjects = []
      this.fetchProjects(id)
    },
    handleChangeContentType() {
      this.content.sourceContentId = null
      this.sourceContentName = this.$t('COPIED_FROM_TEMPLATES')
    },
    setFrequencies() {
      if (this.content.id) {
        this.frequency = this.allFrequencies.find(
          i => i.id === this.content.contentFrequencyId,
        )
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchProjects(id) {
      try {
        const response = await this.fetchProjectByCompanyID(id)
        if (response) {
          this.allProjects = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContents() {
      try {
        const response = await this.fetchAllContents()
        if (response) {
          this.allContents = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContentFrequencies() {
      try {
        const response = await this.fetchAllContentFrequencies()
        if (response) {
          this.allFrequencies = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContentTypes() {
      try {
        const response = await this.fetchAllContentTypes()
        if (response) {
          this.allContentTypes = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchCompanies() {
      try {
        const response = await this.fetchAllCompanies()
        if (response) {
          this.allCompanies = response.data
          if (this.content?.id && this.allCompanies.length === 1) {
            this.handleChangeCompany(this.allCompanies[0].id)
          }
        }
      } catch (error) {
        return error
      }
    },
    clearReferenceID() {
      if (this.content) {
        if (typeof this.content.externalReference === 'string' && this.content.externalReference === '') {
          this.content.externalReference = null
        }
      }
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.content.end = this.content.end === '' ? null : this.content.end
          this.content.contentFrequencyId = this.frequency.id
          const frequencyCheck = this.checkContentFrequency(this.content.length, this.frequency.name, this.content.contentTypeId, this.content.start, this.content.end)
          if (frequencyCheck) {
            this.clearReferenceID()
            if (this.content.id === 0) {
              this.content.contentStatusId = 1
              this.createContent(this.content)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.CONTENT_CREATED'))
                    this.navigateToList()
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            } else {
              this.updateContent(this.content)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.CONTENT_UPDATED'))
                    this.navigateToList()
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            }
          }
        }
      })
    },
    navigateToList() {
      this.$router.push({
        name: 'project-view-id',
        params: {
          id: this.content.projectId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
