import { render, staticRenderFns } from "./ContentConfiguration.vue?vue&type=template&id=10dd0e95&scoped=true"
import script from "./ContentConfiguration.vue?vue&type=script&lang=js"
export * from "./ContentConfiguration.vue?vue&type=script&lang=js"
import style0 from "./ContentConfiguration.vue?vue&type=style&index=0&id=10dd0e95&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10dd0e95",
  null
  
)

export default component.exports