<template>
  <div>
    <b-card>
      <!-- Header: Task & SubTasks -->
      <div class="d-flex">
        <h4 class="mr-1">
          {{ $t('TASK_SUBTASK') }}
        </h4>
        <feather-icon
          v-if="canEditContent"
          :icon="tasks.length === 0 ? 'PlusCircleIcon' : 'EditIcon'"
          size="16"
          class="text-muted cursor-pointer"
          @click="$router.push({ name: 'task-list-id', params: { id: contentId }})"
        />
      </div>
      <div
        v-if="tasks.length === 0"
        class="text-center mt-1"
      >
        <p class="text-muted">
          {{ $t('MESSAGE.NO_TASK_SUBTASK_AVAILABLE') }}
        </p>
      </div>
      <!-- Display all tasks -->
      <div v-else>
        <div
          v-for="(task, index) in tasks"
          :key="index"
        >
          <b-card class="mb-0">
            <!-- Display task name -->
            <h4 class="text-center">
              {{ task.taskName }}
            </h4>
            <p
              v-if="task.taskDescription"
              class="text-center"
              v-html="task.taskDescription"
            />
            <!-- Display questions for the task -->
            <div
              v-for="question in task.questions"
              :key="question.questionID"
            >
              <b-card class="mb-1 p-1 custom-card">
                <!-- Display the question statement -->
                <h5>{{ question.statement }}</h5>
                <p v-if="question.description">
                  {{ question.description }}
                </p>

                <!-- Render options as radio buttons for radio-selection -->
                <div v-if="question.questionType === 'radio-selection'">
                  <label>{{ question.prompt }}</label>
                  <b-form-radio-group
                    v-model="question.selectedOption"
                    :options="getOptionsForRadio(question)"
                  />
                </div>

                <!-- Render options as checkboxes for multiple-selection -->
                <div v-else-if="question.questionType === 'multiple-selection'">
                  <div
                    v-for="option in question.options"
                    :key="option.id"
                  >
                    <b-form-checkbox v-model="option.isSelected">
                      {{ option.name }}
                    </b-form-checkbox>
                  </div>
                </div>

                <!-- Render checkbox for single-option questions -->
                <div
                  v-else-if="question.questionType === 'single-option'"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox v-model="question.answer">
                    {{ question.prompt }}
                  </b-form-checkbox>
                </div>

                <!-- Render text input for text-based questions -->
                <div v-else-if="question.questionType === 'text-input' ">
                  <b-form-input
                    v-model="question.answer"
                    type="text"
                    :placeholder="question.prompt"
                  />
                </div>

                <!-- Render star rating -->
                <div v-else-if="question.questionType === 'rating'">
                  <b-form-rating
                    v-model="question.answer"
                    inline
                    no-border
                    show-value
                    :stars="question.maxValue"
                    variant="primary"
                  />
                </div>

                <!-- Handle unknown question types -->
                <div v-else>
                  <p class="text-muted">
                    {{ $t('MESSAGE.UNSUPPORTED_QUESTION_TYPE') }}
                  </p>
                </div>
              </b-card>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BFormRating,
} from 'bootstrap-vue'
import { mixinList } from '@/constants/mixinValidations'
import {
  mapTasksAndSubtasksToQuestions,
} from '@/constants/utils'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BFormRating,
  },
  mixins: [mixinList],
  props: {
    allContentTasks: {
      type: Array,
      default: () => [],
      required: true,
    },
    canEditContent: {
      type: Boolean,
      default: () => true,
    },
    contentId: {
      type: Number,
      default: 0,
      required: true,
    },

  },
  setup() {
    return {
      mapTasksAndSubtasksToQuestions,
    }
  },
  computed: {
    tasks() {
      try {
        // Call the mapTasksAndSubtasksToQuestions method with allContentTasks
        return mapTasksAndSubtasksToQuestions(this.allContentTasks)
      } catch (error) {
        console.error('Error transforming tasks:', error)
        return []
      }
    },
  },
  methods: {
    getOptionsForRadio(question) {
      return question.options.map(option => ({
        text: option.name,
        value: option.id,
      }))
    },
  },
}
</script>
<style lang="scss">

.custom-card {
  border: 2px solid #ff6400;
  border-radius: 10px;
}

</style>
