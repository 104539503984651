<template>
  <b-modal
    id="task-modal"
    ref="task-modal"
    :title="$t('TEMPLATE')"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @show="initializeModal"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!--/ Task & SubTask  -->
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form-group
          :label="$t('COPIED_FROM_TEMPLATES')"
          label-for="id-content-source"
        >
          <v-select
            id="id-content-source"
            v-model="sourceContent"
            :placeholder="$t('SELECT_CONTENT')"
            label="name"
            :clearable="false"
            class="flex-grow-1"
            :options="filteredContents"
            @input="handleChangeContent($event)"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <div class="text-center">
          <content-task-view
            :content-id="sourceContent.id"
            :all-content-tasks="contentTasks"
            :can-edit-content="false"
          />
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import ContentTaskView from '../content-view/ContentTaskView.vue'

export default {
  components: {
    BModal,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    ContentTaskView,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: useJwt.getUser(),
      contentTasks: [],
      sourceContent: {
        id: 0,
        name: '',
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: '',
        projectId: '',
        companyId: '',
        length: 0,
        start: '',
        end: null,
        tag: '',
      },
    }
  },
  computed: {
    ...mapState('content', ['allContents']),
    filteredContents() {
      return this.allContents.filter(content => content.contentTypeId === this.content.contentTypeId)
    },
  },
  methods: {
    ...mapActions('task', ['fetchContentTaskByID']),
    ...mapActions('content', [
      'fetchAllTemplateContents',
    ]),
    handleOk() {
      this.content.sourceContentId = this.sourceContent.id
      this.$emit('update-source-content', this.sourceContent.name)
    },
    handleCancel() {
      this.content.sourceContentId = null
      this.$emit('update-source-content', this.$t('COPIED_FROM_TEMPLATES'))
      this.resetModal()
    },
    resetModal() {
      this.sourceContent = {
        id: 0,
        name: '',
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: '',
        projectId: '',
        companyId: '',
        length: 0,
        start: '',
        end: null,
        tag: '',
      }
      this.contentTasks = []
      this.$bvModal.hide('task-modal')
    },
    async initializeModal() {
      await this.fetchAllTemplateContents()
    },
    async handleChangeContent(e) {
      const response = await this.fetchContentTaskByID(e.id)
      if (response && response.data) {
        this.contentTasks = response.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '@core/scss/vue/libs/vue-select.scss';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
